import { defineComponent, type PropType } from 'vue'

import type { Cell } from './DataTable'

export default defineComponent({
	name: 'DataTableCell',

	props: {
		cell: { required: true, type: Object as PropType<Cell> },
		index: { required: true, type: Number },
		width: { default: '', type: String },
	},

	computed: {
		cellWidth(): string {
			return this.width ? `style="width: ${this.width}"` : 'auto'
		},
	},

	render() {
		return (
			<td class="mdc-data-table__cell">
				<div style={this.cellWidth}>
					{this.cell.title.link ?
						<a
							class={['js-tooltip', this.cell.title.class]}
							data-test={this.cell.title.dataTest}
							data-test-name={this.cell.title.dataTestName}
							data-tippy-content={this.cell.title.tooltip}
							href={this.cell.title.link}
						>
							{this.cell.title.text}
						</a>
						:
						<span
							class={['js-tooltip', this.cell.title.class]}
							data-test={this.cell.title.dataTest}
							data-tippy-content={this.cell.title.tooltip}
							domProps-innerHTML={this.cell.title.text}
						/>
					}

					{this.cell.details &&
						<span>
							<br />
							{this.cell.details.link ?
								<a
									class={[
										'mdc-data-table__cell-text--info',
										'js-tooltip',
										this.cell.details.class,
									]}
									data-test={this.cell.details.dataTest}
									data-tippy-content={this.cell.details.tooltip}
									href={this.cell.details.link}
								>
									{this.cell.details.text}
								</a>
								:
								<span
									class={[
										'mdc-data-table__cell-text--info',
										'js-tooltip',
										this.cell.details.class,
									]}
									data-test={this.cell.details.dataTest}
									data-tippy-content={this.cell.details.tooltip}
									domProps-innerHTML={this.cell.details.text}
								/>
							}
						</span>
					}
				</div>
			</td>
		)
	},
})
